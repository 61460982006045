<template>
    <div class="language-direction-component">
        <span>{{source ? (type === 'half' ? LANGUAGE_DICT[source].shortName : LANGUAGE_DICT[source].name) : '--'}}</span>
        <template v-if="target">
            <svg-icon name="ib-arrow-right" className="margin-x-5"></svg-icon>
            <span>{{target ? (type === 'half' ? LANGUAGE_DICT[target].shortName : LANGUAGE_DICT[target].name) : '--'}}</span>
        </template>
    </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
    props: {
        source: {
            type: String,
            default: 'zh'
        },
        target: {
            type: String,    
            default: '',
        },
        type: {
            type: String,
            default: 'full', // full, half
        }
    },
    computed: {
        ...mapState({
            LANGUAGE_DICT: state => state.common.LANGUAGE_DICT,
        }),
    }
}
</script>
