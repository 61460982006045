<template>
    <el-upload 
        :multiple="multiple" 
        :limit="limit"
        :accept="accept"
        action=""
        :show-file-list="false"
        :http-request="onUploadEvent"
        :on-exceed="onExceedEvent"
        :before-upload="beforeUploadEvent"
        :headers="headers"
        :data="formData"
        :disabled="edit || loading">
        <template v-if="$slots.default">
            <slot></slot>
        </template>
        <template v-else>
            <el-button round :type="btnType" :size="btnSize" :loading="loading">{{title}}</el-button>
        </template>
    </el-upload>
</template>
<script>
import { mapState } from 'vuex';
import config from '@/utils/config';
import cookie from "vue-cookies";
import md5 from "md5";
import tools from '@/utils/tools';
export default {
    props:{
        multiple:{
            type: Boolean,
            default: false,
        },
        title:{
            type: String,
            default: function(){
                return this.$t('component.UploadOss_title');
            }
        },
        limit:{
            type:Number,
            default:999999999
        },
        accept:{
            type:String,
            default:''
        },
        params:{
            type: Object,
            default: null
        },
        edit:{
            type:Boolean,
            default: false
        },
        size:{
            type: Number,
            default: 999999999,
        },
        btnType: {
            type: String, 
            default: 'primary',
        },
        btnSize: {
            type: String, 
            default: '',
        },
        type: {
            type: String,
            default: '',
        }
    },
    data(){
        return{
            loading: false,
            uploadUrl: '',
            formData: {},
            uploadFileList:[],
            fileData:{},
            headers:{
                'Authorization':cookie.get("token")
            },
        }
    },
    computed: {
		...mapState({
            uploadToken: state => state.common.uploadToken,
		}),
	},
    methods:{ 
        getFiles(){
            let keys = Object.keys(this.fileData);
            return {
                list:this.uploadFileList,
                files:this.fileData,
                successSize:keys.length,
            }
        },
        getOssFiles(){
            let files = this.fileData;
            let keys = Object.keys(files);
            let fileList = [];
            keys.map(key => {
                if (files[key]){
                    fileList.push(files[key]);
                }
            });
            return fileList;
        },
        updateFileList(file){
            let index=-1;
            for(let i=0;i<this.uploadFileList.length;i++){
                if(file.uid === this.uploadFileList[i].uid){
                    index = i;
                    break;
                }
            }
            if(index !== -1){
                this.uploadFileList.splice(index,1,{
                    ...this.uploadFileList[index],
                    ...file,
                    percentage:parseInt(file.percentage),
                    status:file.status
                });
            }
        },
        hasFile(file){
            let rst = this.uploadFileList.filter(item=>item.name===file.name&&item.size===file.size);
            return rst.length > 0;
        },
        deleteFileEvent(index){
            let file = this.uploadFileList[index];
            this.uploadFileList.splice(index,1);
            delete this.fileData[file.uid];
        },
        getMd5Name(file){
            let names = file.name.split('.');
            let type = '.'+names[names.length-1];
            let noTypeNames = names.slice(0, names.length-1);
            let noTypeName = noTypeNames.join('.');
            let noTypeNameMd5 = md5(noTypeName);
            return noTypeNameMd5 + type;
        },
        beforeUploadEvent(file) {
            const userInfo = localStorage.getItem('userInfo');
            const token = cookie.get('token');
            const isLogin = token && userInfo ? true : false;
            if(!isLogin){
                this.$message.error(this.$t('common.response_401'));
                this.$router.replace('/');
                return false;
            }
            let names = file.name.toLocaleLowerCase().split('.');
            let type = '.'+names[names.length-1];
            console.log(`[INFO] %cMIME TYPE: %c${file.type}; %cFILE FORMAT: %c${type}`, 'font-weight:bolder', '', 'font-weight:bolder', '');
            let nameMd5 = this.getMd5Name(file);
            let acceptTypes = this.accept.split(',');
            if(this.accept){
                if(acceptTypes.indexOf(type) < 0){
                    this.$message.error(this.$t('component.UploadOss_file_accept_message', {accept: this.accept}));
                    return false;
                }
            }
            if(!this.multiple){
                this.uploadFileList = [];
                this.fileData = {};
            }
            if(this.uploadFileList.length === this.limit){
                this.$message.error(this.$t('component.UploadOss_file_limit_message', {limit: this.limit}));
                return false;
            }
            if(file.size === 0){
                this.$message.error(this.$t('component.UploadOss_file_empty_message'));
                return false;
            }
            if(file.size > this.size * 1024 * 1024){
                this.$message.error(this.$t('component.UploadOss_file_size_message', {name: file.name, size: this.size}));
                return false;
            }
            if(!this.hasFile(file)){
                this.uploadFileList.push({
                    name: file.name,
                    nameMd5,
                    uid: file.uid,
                    size: file.size,
                    percentage: 0,
                    raw: file,
                    fileType: config.FILE_TYPE_MATCH[type],
                    status: ''
                });
            }else{
                this.$message.error(this.$t('component.UploadOss_file_exist_message', {name: file.name}));
                return false;
            }
        },

        onUploadEvent(param){
            // console.log('onUploadEvent::', param);
            let file = param.file;
            let nameMd5 = this.getMd5Name(file);
            let config = {
                onUploadProgress: (progressEvent) => {
                    let  complete = ((progressEvent.loaded / progressEvent.total) * 100) | 0;
                    file.percentage = complete;
                    this.updateFileList(file);
                }
            }
            this.loading = true;
            this.$store.dispatch('common/initUploadTokenAction', this.type).then(res => {
                const uploadToken = res;
                let params = {
                    ...uploadToken.formData,
                    key: uploadToken.prefixKey + nameMd5,
                    file: param.file,
                };
                this.$ajax.fileUpload(uploadToken.host, params, config).then(res =>{
                    file.status = 'success';
                    this.updateFileList(file);
                    let tmpData = this.fileData;
                    this.fileData = {
                        ...tmpData, 
                        [file.uid]: {
                            fileName: file.name,
                            objectName: uploadToken.prefixKey + nameMd5,
                            size: file.size,
                        }
                    }; 
                    this.$nextTick(()=>{
                        if(this._events.success){
                            this.$emit('success');
                        }
                    });
                }).catch(err =>{
                    this.$message.error(this.$t('component.UploadOss_file_upload_fail_message'));
                    file.status = 'fail';
                    this.updateFileList(file);
                }).finally(()=>{
                    this.loading = false;
                });
            })
            
        },
        onExceedEvent(files, fileList){
            this.$message.error(this.$t('component.UploadOss_file_limit_message', {limit: this.limit}));
        }

    },
    watch: {
        
    },
    mounted(){
        this.uploadUrl = this.uploadToken.host;
        this.formData = {
            ...this.formData,
            ...this.uploadToken.formData,
        }
    }
}
</script>
<style lang="scss" scoped>

</style>


