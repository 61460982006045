import Base62x from './Base62x';
import cookie from 'vue-cookies';
export default class IWebSocket {
    constructor(url, callback, heart = false) {
        this.url = url;
        this.callback = callback;
        this.ws = null;
        this.status = 0; // 0: 关闭 1: 已连接 2: 手动关闭
        this.heart = heart;
        this.heartPing = 10000; //心跳时长10s
        this.heartPingTimer = null;
        this.reconnect = 1000;
    }

    connect() {
        let token = cookie.get('token');
        let org = cookie.get('org');
        var tokens = Base62x.encode(token + "," + org);
        this.ws = new WebSocket(this.url, [tokens]);
        this.ws.onopen = () => {
            console.log('[INFO]', 'websocket连接成功：', this.ws.readyState);
            this.status = 1;
            if (this.heart) {
                this.healthCheck();
            }
        }
        this.ws.onmessage = (event) => {
            this.callback(JSON.parse(event.data));
        }
        this.ws.onclose = (event) => {
            this.onClose(event);
        }
        this.ws.onerror = (event) => {
            console.error('[ERROR]', 'WebSocket error', event);
        }
    }

    send(data) {
        if(this.status === 1){
            this.ws.send(JSON.stringify(data));
        }else {
            const limitSecond = 2 * 1000;
            const timerSecond = 100;
            let time = 1;
            const sendTimer = setInterval(() => {
                console.log('[INFO]', 'WebSocket attempt to send message: ', time);
                if(this.status === 1) {
                    clearInterval(sendTimer);
                    this.ws.send(JSON.stringify(data));
                }else {
                    if(time * timerSecond >= limitSecond){
                        clearInterval(sendTimer);
                        console.error('[ERROR]', 'WebSocket send error,', `The websocket connection exceeds ${limitSecond / 1000} seconds.`);
                    }else {
                        time ++;
                    }
                }
            }, timerSecond);
        }

    }

    close() {
        this.status = 2;
        this.ws.close();
    }

    onClose (event) {
        console.log('[INFO] WebSocket closed::status=%d event=%o', this.status, event, this.ws);
        this.status = this.status === 2 ? this.status : 0;
        setTimeout(() => {
            if (this.status === 0) {
                this.connect();
            }
        }, this.reconnect);
    }

    healthCheck() {
        const data = {
            type: 'health',
        };
        this.heartPingTimer = setInterval(() => {
            if (this.status === 1) {
                this.ws.send(JSON.stringify(data));
            }else {
                clearInterval(this.heartPingTimer);
            }
        }, this.heartPing);
    }
}
