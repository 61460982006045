<template>
    <div class="alert-dialog-component" >
        <el-dialog :title="$t('component.AlertDialog_title')" :visible.sync="show" destroy-on-close width="480px" top="20px">
            <div class="alert-dialog-main">
                <div class="message-content">{{message}}</div>
                <div class="submessage-content" v-if="submessage">{{submessage}}</div>
                <div class="actions">
                    <el-button plain round size="small" @click="cancelBtnEvent" v-if="type === 'confirm'">{{cancelButtonName}}</el-button>
                    <el-button round :type="buttonType" size="small" @click="confirmBtnEvent">{{buttonName}}</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name:'AlertDialog',
    props:{
        message:{
            type:String, 
            default:''
        },
        submessage:{
            type:String, 
            default:''
        },
        type:{
            type:String, 
            default:'alert'
        }, //alert ,confirm
        buttonName:{
            type:String, 
            default: function(){
                return this.$t('component.AlertDialog_button_confirm');
            }
        },
        buttonType:{
            type:String, 
            default: 'primary'
        },
        cancelButtonName: {
            type:String, 
            default: function(){
                return this.$t('component.AlertDialog_button_cancel');
            }
        },
    },
    data(){
        return{
            show:false,
        }
    },
    methods:{
        cancelBtnEvent(){
            if(this._events.cancelEvent){
                this.$emit('cancelEvent');
            }
            this.setShow(false);
        },
        confirmBtnEvent(){
            if(this._events.confirmEvent){
                this.$emit('confirmEvent');
            }
            this.setShow(false);
        },
        setShow(is){
            this.show = is;
        }
    }    
}
</script>
<style lang="scss" scoped>

</style>

