<template>
    <div class="upload-files-component" :disabled="edit">
        <el-upload 
            :drag="drag"
            action=""
            :multiple="multiple" 
            :accept="accept"
            :show-file-list="false"
            :http-request="onUploadEvent"
            :on-exceed="onExceedEvent"
            :before-upload="beforeUploadEvent"
            :headers="headers"
            :data="formData">
            <slot></slot>
        </el-upload>
        <template v-if="showList">
            <div class="files-list" v-if="uploadFileList.length > 0">
                <div class="item" v-for="(file, index) in uploadFileList" :key="index">
                    <div class="fonts-14 margin-r-5">
                        <svg-icon :name="'ic-'+file.fileType.icon"></svg-icon>
                    </div>
                    <div class="name">{{file.name}}</div>
                    <div class="progress">
                        <span class="fonts-16" style="color:#D7343F;" v-if="file.status==='fail'">
                            <svg-icon name="ib-error-o"></svg-icon>
                        </span>
                        <span class="fonts-16" style="color:#00B670;" v-else-if="file.status==='success'">
                            <svg-icon name="ib-check-circle"></svg-icon>
                        </span>
                        <span v-else>{{file.percentage}}%</span>
                    </div>
                    <div class="delete-btn" @click="deleteFileEvent(index)">
                        <svg-icon name="ib-close" className="close-btn"></svg-icon>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import config from '@/utils/config';
import cookie from "vue-cookies";
import md5 from "md5";
export default {
    props:{
        title:{
            type: String,
            default: function(){
                return this.$t('component.UploadOss_title');
            }
        },
        drag: {
            type: Boolean,
            default: false,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        showList: {
            type: Boolean,
            default: false,
        },
        accept:{
            type: String,
            default: ''
        },
        params:{
            type: Object,
            default: null,
        },
        /** 上传组件是否可用 */
        edit:{
            type: Boolean,
            default: false,
        },
        /** 单个文件大小 */
        size:{
            type: Number,
            default: 999999999,
        },
        limit: {
            type: Number,
            default: 999999999,
        },
        type: {
            type: String,
            default: '',
        }
    },
    data(){
        return{
            uploadUrl: '',
            formData: {},
            uploadFileList:[],
            fileData:{},
            headers:{
                'Authorization':cookie.get("token")
            },
        }
    },
    computed: {
		...mapState({
            uploadToken: state => state.common.uploadToken,
		}),
        successSize() {
            return this.uploadFileList.filter(file => file.status === 'success').length;
        },
        failSize() {
            return this.uploadFileList.filter(file => file.status === 'fail').length;
        },
	},
    methods:{ 
        getFiles(){
            let keys = Object.keys(this.fileData);
            return {
                list:this.uploadFileList,
                files:this.fileData,
                successSize:keys.length,
            }
        },
        getOssFiles(){
            let files = this.fileData;
            let keys = Object.keys(files);
            let fileList = [];
            keys.map(key => {
                if (files[key]){
                    fileList.push(files[key]);
                }
            });
            return fileList;
        },
        reset(){
            this.fileData = {};
            this.uploadFileList = [];
        },
        updateFileList(file){
            let index=-1;
            for(let i=0;i<this.uploadFileList.length;i++){
                if(file.uid === this.uploadFileList[i].uid){
                    index = i;
                    break;
                }
            }
            if(index !== -1){
                this.uploadFileList.splice(index,1,{
                    ...this.uploadFileList[index],
                    ...file,
                    percentage:parseInt(file.percentage),
                    status:file.status
                });
            }
        },
        hasFile(file){
            let rst = this.uploadFileList.filter(item => item.name === file.name && item.size === file.size);
            return rst.length > 0;
        },
        deleteFileEvent(index){
            let file = this.uploadFileList[index];
            console.log(file, this.fileData);
            this.uploadFileList.splice(index,1);
            delete this.fileData[file.uid];
        },
        getMd5Name(file){
            let names = file.name.split('.');
            let type = '.'+names[names.length-1];
            let noTypeNames = names.slice(0, names.length-1);
            let noTypeName = noTypeNames.join('.');
            let noTypeNameMd5 = md5(noTypeName);
            return noTypeNameMd5 + type;
        },
        beforeUploadEvent(file) {
            const userInfo = localStorage.getItem('userInfo');
            const token = cookie.get('token');
            const isLogin = token && userInfo ? true : false;
            if(!isLogin){
                this.$message.error(this.$t('common.response_401'));
                this.$router.replace('/');
                return false;
            }
            let names = file.name.toLocaleLowerCase().split('.');
            let type = '.'+names[names.length-1];
            console.log(`[INFO] %cMIME TYPE: %c${file.type}; %cFILE FORMAT: %c${type}`, 'font-weight:bolder', '', 'font-weight:bolder', '');
            let nameMd5 = this.getMd5Name(file);
            let acceptTypes = this.accept.split(',');
            if(this.accept){
                if(acceptTypes.indexOf(type) < 0){
                    this.$message.error(this.$t('component.UploadOss_file_accept_message', {accept: this.accept}));
                    return false;
                }
            }
            if(!this.multiple){
                this.uploadFileList = [];
                this.fileData = {};
            }
            if(this.uploadFileList.length === this.limit){
                this.$message.error(this.$t('component.UploadOss_file_limit_message', {limit: this.limit}));
                return false;
            }
            if(file.size === 0){
                this.$message.error(this.$t('component.UploadOss_file_empty_message'));
                return false;
            }
            if(file.size > this.size * 1024 * 1024){
                this.$message.error(this.$t('component.UploadOss_file_size_message', {name: file.name, size: this.size}));
                return false;
            }
            if(!this.hasFile(file)){
                this.uploadFileList.push({
                    name: file.name,
                    nameMd5,
                    uid: file.uid,
                    size: file.size,
                    percentage: 0,
                    raw: file,
                    fileType: config.FILE_TYPE_MATCH[type],
                    status: ''
                });
            }else{
                this.$message.error(this.$t('component.UploadOss_file_exist_message', {name: file.name}));
                return false;
            }
        },

        onUploadEvent(param){
            // console.log('onUploadEvent::', param);
            let file = param.file;
            let nameMd5 = this.getMd5Name(file);
            let config = {
                onUploadProgress: (progressEvent) => {
                    let  complete = ((progressEvent.loaded / progressEvent.total) * 100) | 0;
                    file.percentage = complete;
                    this.updateFileList(file);
                },
            }
            
            this.$store.dispatch('common/initUploadTokenAction', this.type).then((res) => {
                const uploadToken = res;
                let params = {
                    ...uploadToken.formData,
                    key: uploadToken.prefixKey + nameMd5,
                    file: param.file,
                };
                this.$ajax.fileUpload(uploadToken.host, params, config).then(res =>{
                    file.status = 'success';
                    this.updateFileList(file);
                    let tmpData = this.fileData;
                    let ossData = {
                        fileName: file.name,
                        objectName: uploadToken.prefixKey + nameMd5,
                        size: file.size,
                    };
                    this.fileData = {
                        ...tmpData, 
                        [file.uid]: ossData,
                    };
                    this.$nextTick(()=>{
                        if(this._events.success){
                            this.$emit('success', ossData);
                        }
                    });
                }).catch(err =>{
                    file.status = 'fail';
                    this.updateFileList(file);
                    this.$nextTick(()=>{
                        if(this._events.error){
                            this.$emit('error', file);
                        }
                    });
                });
            });
            
        },
        onExceedEvent(files, fileList){
            this.$message.error(this.$t('component.UploadOss_file_limit_message', {limit: this.limit}));
        }

    },
    watch: {
        uploadFileList(val, oldVal) {
            if(this._events.change){
                this.$emit('change', val);
            }
        }
    },
    mounted(){
        this.uploadUrl = this.uploadToken.host;
        this.formData = {
            ...this.formData,
            ...this.uploadToken.formData,
        }
    }
}
</script>
<style lang="scss" scoped>
.upload-files-component{
    width: 100%;
    &:disabled,
    &[disabled] {
        cursor: not-allowed;
        opacity: .5;
    }
    ::v-deep .el-upload{
        width: 100%;
        text-align: left;
        .el-upload-dragger {
            width: 100%;
            height: auto;
            border-color: transparent;
        }
    }
    .files-list{
        margin-top: 10px;
        max-height: 200px;
        padding: 0 10px;
        overflow-y: auto;
        .item{
            display: flex;
            align-items: center;
            height: 30px;
            // border-bottom: 1px solid #0000001A;
            // box-sizing: border-box;
            font-size: 12px;
            // max-width: 80%;
            min-width: 0;
            line-height: normal;
            .file-type{
                svg{
                    font-size: 16px;
                }
            }
            .progress{
                // margin-left: 10px;
                // width: 37px;
            }
            .name{
                flex: 1;
                white-space:nowrap;
                text-overflow:ellipsis;
                overflow:hidden;
                margin-right: 10px;
            }
            .delete-btn{
                margin-left: 10px;
                display: none;
                cursor: pointer;
                .close-btn{
                    width: 12px;
                    height: 12px; 
                    color: #00000066;
                    &:hover{
                        color: #000;
                    }
                }
            }
            &:hover {
                .delete-btn {
                    display: block;
                }
            }
        }
    }
    
}
</style>

